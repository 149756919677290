import DropdownButton from "@components/DropdownButton";
import InsightCard from "@components/InsightCard";
import Layout from "@components/Layout";
import { Subtitle } from "@components/Typography";
import { graphql } from "gatsby";
import Isotope from "isotope-layout/js/isotope";
import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { useBreakpoint } from "../utilities";
import Seo from 'gatsby-plugin-wpgraphql-seo';

const Container = styled.div(() => [
  tw`flex flex-1 flex-col bg-pink pb-[100px] w-[100vw] max-w-[1300px] self-center min-h-[60vh]`,
]);

const SearchContainer = styled.div(() => [
  tw`flex flex-col-reverse tablet:flex-row self-center mt-[75px] w-[100vw] max-w-[1300px] justify-between px-[25px]`,
]);

const FilterContainer = styled.div(() => [tw`mx-[25px]`]);

const Dropdown = styled.div(({ isOpen }) => [
  tw`flex flex-col transition-all duration-500 items-center tablet:items-start`,
  isOpen ? tw`h-[200px]` : tw`h-[0px] overflow-hidden`,
]);

const FilterItem = styled.div(({ featured }) => [
  featured
    ? tw`laptop:w-[48.66666%] tablet:w-[65.333333%] mobile:w-[49%] w-[calc(100%)]`
    : tw`laptop:w-[23%] tablet:w-[32%] mobile:w-[49%] w-[calc(100%)]`,
  tw`mb-[35px] flex justify-center`,
]);

const GridSizer = styled.div(() => [
  tw`laptop:w-[23%] tablet:w-[32%] mobile:w-[49%] w-[calc(100%)]`,
]);

const GutterSizer = styled.div(() => [
  tw`laptop:w-[2.666666%] tablet:w-[1.33333%] mobile:w-[2%] w-[0%]`,
]);

const Filters = styled.div(() => [
  tw`flex flex-col gap-[20px] pb-[50px] justify-center tablet:py-[50px]`,
]);

const Search = styled.div(() => [
  tw`flex flex-row gap-[20px] py-[50px] max-h-[100px] w-[100%] tablet:w-[300px] justify-center`,
]);

const Filter = styled.a(({ active }) => [
  active
    ? tw`before:content-[''] italic before:block before:w-[15px] before:mr-[10px] before:border-b-[2px] before:border-gold after:block after:w-[15px] after:ml-[10px] after:border-b-[2px] after:border-gold tablet:after:hidden`
    : tw`tablet:ml-[25px]`,
  tw`flex flex-row items-center font-sans tablet:hover:font-bold text-midnight`,
]);

const InsightsArchive = ({ data: { posts, categories, wp } }) => {
  const { nodes } = posts;
  const { cats } = categories;

  const isotope = React.useRef();
  const [filterKey, setFilterKey] = React.useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const point = useBreakpoint();

  const openDropdown = () => {
    setOpen((open) => !open);
  };

  React.useEffect(() => {
    setTimeout(() => {
      isotope.current = new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: "masonry",
        percentPosition: true,
        masonry: {
          columnWidth: ".grid-sizer",
          gutter: ".gutter-sizer",
        },
        hiddenStyle: {
          opacity: 0,
        },
        visibleStyle: {
          opacity: 1,
        },
      });
    }, 100);

    // cleanup
    return () => isotope.current.destroy();
  }, []);

  useEffect(() => {
    isotope?.current?.arrange({
      filter: (itemElem) => {
        let title = itemElem.querySelector(".filter-title").innerText;
        return title.toLowerCase().search(searchValue.toLowerCase()) >= 0;
      },
    });
  }, [searchValue]);

  useEffect(() => {
    filterKey.length
      ? isotope?.current?.arrange({ filter: `.${filterKey.join(", .")}` })
      : isotope?.current?.arrange({ filter: "*" });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => {
    let newKey = key?.replace(/\s+/g, "-")?.toLowerCase() || "*";

    if (newKey === "clear-filters") {
      setFilterKey([]);
    } else if (filterKey.includes(newKey)) {
      setFilterKey((state) => state.filter((item) => item !== newKey));
    } else {
      setFilterKey((state) => [...state, newKey]);
    }
  };

  return (
    <Layout>
      <Seo
        title="Insights - Think Tutors"
        postSchema={JSON.parse(wp.seo.contentTypes.post.schema.raw)}
      />
      <SearchContainer>
        <Filters>
          <DropdownButton
            isOpen={isOpen}
            onClick={openDropdown}
            text="Filter"
            size="large"
            color="midnight"
            dropdown
          ></DropdownButton>
          <Dropdown isOpen={isOpen}>
            {[...cats, { name: "Clear Filters" }]?.map((item, index) => {
              const { name } = item;
              const active = filterKey.includes(
                name.replace(/\s+/g, "-")?.toLowerCase()
              );

              return (
                <Filter
                  onClick={() => handleFilterKeyChange(name)}
                  href="#"
                  active={active}
                >
                  {name}
                </Filter>
              );
            })}
          </Dropdown>
        </Filters>

        <Search>
          {point !== "xs" && point !== "sm" && (
            <Subtitle color="midnight" preventLarge>
              Search
            </Subtitle>
          )}
          <input
            className="bg-pink border-[1px] border-midnight px-[10px] h-[40px] flex items-center w-full placeholder-midnight"
            type="text"
            placeholder={point === "xs" || point === "sm" ? "Search" : ""}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Search>
      </SearchContainer>

      <Container>
        <FilterContainer className="filter-container">
          <GridSizer className="grid-sizer"></GridSizer>
          <GutterSizer className="gutter-sizer"></GutterSizer>
          {nodes?.map((item, index) => {
            const {
              excerpt,
              title,
              categories,
              uri,
              date,
              insights: { featured },
            } = item;

            let caseStudyClases = categories.nodes.map(
              (item) => item?.name?.replace(/\s+/g, "-")?.toLowerCase() || ""
            );

            return (
              <FilterItem
                className={`filter-item ${caseStudyClases.join()}`}
                featured={featured}
              >
                <InsightCard
                  date={date}
                  link={uri}
                  color="green"
                  excerpt={excerpt}
                  title={title}
                  featured={featured}
                />
              </FilterItem>
            );
          })}
        </FilterContainer>
      </Container>
    </Layout>
  );
};

export default InsightsArchive;

export const pageQuery = graphql`
  query AllPosts {
    # selecting the current post by id
    posts: allWpPost {
      nodes {
        id
        content
        title
        uri
        date
        excerpt
        insightIntro {
          intro
        }
        insights {
          featured
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    categories: allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
      cats: nodes {
        name
      }
    }
  }
`;
