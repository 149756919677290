import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Title, Subtitle } from "@components/Typography";
import GetInTouch from "@images/svgs/getintouch.inline.svg";

const Link = styled.a(() => [tw``]);

const Row = styled.div(() => [
  tw`justify-start`,
  tw`flex flex-row gap-[10px] items-center`,
]);

const ButtonContainer = styled.div(({isOpen = false}) => {
  return [
    tw`transform duration-500`,
    isOpen ? tw`rotate--90` : tw`rotate-90`]
});

const Button = ({
  text,
  link = "#",
  color = "gold",
  size = null,
  onClick = () => {},
  isOpen=false
}) => {

  const colorVariants = {
    transparent: "transparent",
    current: "currentColor",
    white: "#ffffff",
    green: "#1D3F37",
    gold: "#D4AF37",
    pink: "#FAEAE8",
    midnight: "#1B2040",
    form: "#323653"
  }


  return (
    <div className="justify-center items-center self-center tablet:self-start">
    <Link href={link} onClick={onClick} className="cursor-pointer">
      <Row className="transform hover:scale-110 duration-500">
        <Subtitle
          color={color}
          className={
            size === "small"
              ? "!text-[16px]"
              : size === "large"
              ? "!text-[28px]"
              : "!text-[21px]"
          }>
          {text}
        </Subtitle>
        <ButtonContainer isOpen={isOpen} className="items-center flex flex-col justify-center">
          <GetInTouch
            fill={colorVariants["gold"]}
            stroke={colorVariants["gold"]}
            width="30px"
            className={`relative`}
          />
        </ButtonContainer>
        
      </Row>
    </Link>
    </div>

  );
};

export default Button;
